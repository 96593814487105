import initialState from '../../demoData/usersData.json';
import initialStateGroup from '../../demoData/usersGroupData.json';

const initialStateUser = {
  user: {},
};
const initialStateProfile = {
  profile: {},
};
const userReducer = (state = initialState) => {
  return state;
};

const userGroupReducer = (state = initialStateGroup) => {
  return state;
};

const userInfo = (state = initialStateUser) => {
  return state;
};
const userProfile = (state = initialStateProfile) => {
  return state;
};

export { userReducer, userGroupReducer, userInfo, userProfile };
