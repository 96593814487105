/* eslint-disable no-unused-expressions */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useClient } from './useClient';

export function useFetchQuery(url, $config, options) {
  const client = useClient();
  const { key, ...config } = $config || {};

  return useQuery(key ? [key, config.query] : [url, config?.payload], () => client(url, config), {
    ...options,
    onError: (...args) => {
      options?.onError ? options.onError(...args) : message.error(args[0].message || 'Something went wrong');
    },
  });
}

export function useMutateQuery(options) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(({ url = '', ...config }) => client(url, { method: 'POST', ...config }), {
    ...options,
    onSuccess: (data, req, context) => {
      if (options?.invalidateUrls) {
        options.invalidateUrls.forEach((v) => {
          queryClient.invalidateQueries({ queryKey: [v] });
        });
      }
      // eslint-disable-next-line no-unused-expressions
      options?.onSuccess && options.onSuccess(data, req, context);
    },
    onError: (...args) => {
      options?.onError ? options.onError(...args) : message.error(args[0].message || 'Something went wrong');
    },
  });
}

export function useFetchQueryWithQuery(url, $config, options) {
  // const router = useRouter();
  const configData = {
    payload: { ...$config?.defaultQuery },
    ...$config,
  };

  const data = useFetchQuery(url, configData, {
    ...options,
    // onError: (...args) => {
    //   options?.onError
    //     ? options.onError(...args)
    //     : message.error(args[0].message || "Something went wrong");
    // },
  });

  return data;
}
