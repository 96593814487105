/* eslint-disable prefer-promise-reject-errors */
import qs from 'qs';
import axios from 'axios';

export default async function api(url, config) {
  const { type, token, payload, method = 'GET', ...rest } = config || {};

  // const modifiedPayload = { ...payload };
  let modifiedPayload;
  const checkArr = Array.isArray(payload);

  if (checkArr === true) {
    // modifiedPayload = {
    //   payload
    //   // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    // }
    modifiedPayload = payload;
  } else {
    modifiedPayload = {
      ...payload,
      // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  rest.headers = rest.headers || {};
  rest.baseURL = process.env.REACT_APP_API_ENDPOINT;
  // eslint-disable-next-line dot-notation, prefer-template
  rest.headers['Authorization'] = token ? 'Bearer ' + token : `${process.env.NEXT_PUBLIC_BASIC_AUTH_TOKEN}`;
  // rest.withCredentials = true;
  // rest.withCredentials = true;
  switch (type) {
    case 'multipart':
      rest.headers['Content-Type'] = 'multipart/form-data';
      break;
    case 'csv':
      // eslint-disable-next-line dot-notation
      rest.headers['Accept'] = 'application/json';
      rest.responseType = 'blob';
      break;

    case 'form':
      rest.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      break;

    default:
      rest.headers['Content-Type'] = 'application/json';
  }

  // eslint-disable-next-line no-return-await
  return await axios({
    url,
    method,
    ...rest,
    [method !== 'GET' ? 'data' : 'params']: type === 'form' ? qs.stringify(modifiedPayload) : modifiedPayload,
  })
    .then((res) => {
      const { data, status, statusText } = res;
      return status < 400
        ? Promise.resolve({ data, message: statusText })
        : Promise.reject({ code: status, message: statusText });
    })
    .catch((err) => {
      if (err?.response?.data?.errorCode === 401) {
        // logout()
      }
      const data = err.response?.data;
      return Promise.reject({
        code: data?.errorCode || err.response?.status,
        message: data?.errorMessage || data?.error_description,
      });
    });
}
