import React from 'react';
import { Row, Col } from 'antd';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Row align="middle">
        <Col className="mx-auto sm:mt-[5rem] mt-[2.875rem] my-auto" xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
