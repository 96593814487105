/* eslint-disable import/no-named-as-default */
// import useLogout from 'hooks/useLogout';

import { useSelector } from 'react-redux';
import api from '../utility/api';

export function useClient() {
  const { token } = useSelector((state) => {
    return {
      token: state.auth?.login?.jwt,
    };
  });

  // const logout = useLogout();

  const handleLogout = () => {
    // logout()
  };

  return (url, config) => {
    return api(url, { token, method: 'GET', ...config })
      .then((res) => Promise.resolve(res?.data))
      .catch((err) => {
        // eslint-disable-next-line default-case
        switch (err.code) {
          case 401:
            handleLogout();
            break;
        }

        return Promise.reject(err);
      });
  };
}
